import React, { memo as Memo, useCallback, useState } from 'react'
import { withRouter } from 'next/router'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Components
import Nav from './types/nav';
import Default from './types/default';
import Social from './types/social';
import Custom from './types/custom';
import Translate from '@components/global/Translate'

const MenuItem = Memo(props => {
    //! States
    const [isActive, setIsActive] = useState(false)

    const checkType = useCallback(() => {
        const type = props.type;

        if (type === "nav") {
            return (
                props.svg ?
                    <div className='navCont'>
                        <span className={"Nav" + props.classes}
                            onMouseMove={props.onMouseMove}
                            onMouseOver={props.onMouseMove}
                            onMouseOut={props.onMouseOut}
                        >
                            <Nav className={props.classes} path={props.path} >
                                <Translate val={props.item.name} />
                            </Nav>
                        </span>
                    </div>
                    :
                    <Nav className={props.classes} path={props.path}>
                        <Translate val={props.item.name} />
                    </Nav>


            )
        }
        else if (type === "custom") {
            return (
                <Custom path={`${props.path}`} target={props.item.target}>
                    <Translate val={props.item.name} />
                </Custom>
            )
        }
        else if (type === "social") {
            return <Social path={props.item.path} icon={props.item.iconName || props.item.name} />
        }
        else {
            return (
                <Default path={props.path}>
                    <Translate val={props.item.name} />
                </Default>
            )
        }
    }, [props.type, props.path, props.item, props.onMouseMove, props.onMouseOut, props.svg, props.classes])

    const onClick = useCallback(() => {
        if (props.winWidth < props.screenSizes.screenLg) {
            props.children && setIsActive(!isActive)
        }
        props.onClick && props.onClick()
    }, [props.winWidth, props.onClick, props.children, props.screenSizes.screenLg, isActive])

    return (
        <li className={`${props.classes} ${isActive ? 'active' : ''}`}
            onClick={onClick}
        >
            {checkType()}
            {props.children}
        </li>
    )
})

export default withRouter(withUIContext(MenuItem, ['screenSizes', 'winWidth']))
