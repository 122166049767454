import React, { memo as Memo, useState, useCallback, useEffect } from 'react'

//* Components
import RotationAnimation from '../SvgComponents/RotationAnimation'

//* Style
import style from './style.module.scss'

const OrientationPopup = Memo(() => {
    const [landscape, setLandscape] = useState(false)

    useEffect(() => {
        checkOrientation()
    },[])

    useEffect(() => {
        typeof window !== 'undefined' && window.addEventListener('resize', checkOrientation)

        return () => {
            window.removeEventListener('resize', checkOrientation)
        }
    }, [landscape])

    const checkOrientation = useCallback((e) => {
        setLandscape((window.innerWidth <= 812 && window.innerWidth > window.innerHeight))
    }, [landscape])

    return landscape && (
        <div className={style.orPopup}>
            <div className={style.orCont}>
                <div className={style.orImage}>
                    <RotationAnimation />
                </div>

                <p className="h6 font-poppins font-medium">Please rotate your device</p>

                <div className={style.orLock}>
                    <svg viewBox="0 0 48.77 64">
                        <path d="M50.29,21.34h-3v-6.1a15.24,15.24,0,0,0-30.48,0h5.79a9.45,9.45,0,0,1,18.9,0h0v6.1H13.71a6.1,6.1,0,0,0-6.09,6.08V57.9A6.11,6.11,0,0,0,13.71,64H50.29a6.11,6.11,0,0,0,6.09-6.1V27.42A6.1,6.1,0,0,0,50.29,21.34ZM32,49.07A6.1,6.1,0,1,1,38.1,43,6.09,6.09,0,0,1,32,49.07Z" transform="translate(-7.62)" />
                    </svg>
                </div>

                <p className="p-l font-poppins font-light">If screen doesn’t rotate, make sure your screen orientation is unlocked</p>
            </div>
        </div>
    )
})

export default OrientationPopup