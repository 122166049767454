import React, { memo as Memo } from 'react';

const Social = Memo(props => {
    return (
        <a className={`crCircle`} href={props.path} target="_blank" aria-label='social-links' rel='noopener noreferrer'>
            <i className={`${props.icon}`} />
        </a>
    )
})

export default Social
