import React from 'react';

const SocIconStroke1 = () => {
    return (
        <svg className="svg-max" preserveAspectRatio="none" viewBox="0 0 60  64.31">
            <path d="M26.081,64.315A27.442,27.442,0,0,1,18.3,63.188C5.458,59.4,1.467,51.065.379,44.739c-2-11.644,4.28-24.245,9.668-28.156l1.766,2.375C7.164,22.37,1.547,33.923,3.332,44.23,4.7,52.212,10.027,57.62,19.147,60.314c11.384,3.362,23.756-1.906,31.609-13.42,6.6-9.718,9.479-24.305,1.078-34.991C45.328,3.6,36.408,1.088,25.333,4.39a51.933,51.933,0,0,0-15.206,7.743L8.281,9.778A53.879,53.879,0,0,1,24.4,1.547C36.637-2.135,46.894.808,54.188,10.027c9.339,11.883,6.246,27.937-1,38.533C46.415,58.628,36.308,64.315,26.081,64.315Z" transform="translate(0 -0.004)" />
        </svg>

    )
}
const SocIconStroke2 = () => {
    return (
        <svg className="svg-max" preserveAspectRatio="none" viewBox="0 0 60 65">
            <path  d="M34.335,65.029c-13.441,0-25.873-9.7-26.053-9.849l1.846-2.371a51.721,51.721,0,0,0,15.207,7.831c11.086,3.34,19.956.787,26.5-7.579,8.4-10.808,5.518-25.562-1.078-35.391C42.906,6.055,30.543.666,19.148,4.036,10.028,6.791,4.7,12.271,3.333,20.334c-1.786,10.475,3.832,22.11,8.481,25.531l-1.766,2.442C4.66,44.4-1.626,31.606.379,19.829,1.467,13.431,5.458,5.045,18.3,1.17,30.962-2.614,44.682,3.189,53.224,15.964c7.194,10.747,10.287,26.954,1,38.963C48.3,62.567,41.19,65.029,34.335,65.029Z" transform="translate(0.238 -0.086)" />
        </svg>
    )
}
const SocIconStroke3 = () => {
    return (
        <svg  className="svg-max" preserveAspectRatio="none" viewBox="0 0 60 65">
            <path d="M25.869,65.03c-7.987,0-14.777-3.419-19.968-10.136-9.345-12-6.25-28.239,1-38.94C15.376,3.2,29.1-2.612,41.774,1.17c12.85,3.873,16.843,12.254,17.932,18.648,2,11.77-4.283,24.507-9.675,28.461l-1.767-2.441c4.653-3.419,10.274-15.047,8.487-25.506-1.368-8.068-6.7-13.545-15.815-16.3C29.533.675,17.113,6.051,9.3,17.659,2.7,27.482-.19,42.227,8.217,53.028c6.52,8.381,15.475,10.922,26.588,7.554a51.726,51.726,0,0,0,15.116-7.806l1.847,2.37a53.328,53.328,0,0,1-16.134,8.33A33.733,33.733,0,0,1,25.869,65.03Z" transform="translate(-0.085 -0.03)"/>
        </svg>
    )
}
const SocIconStroke4 = () => {

    return (
        <svg className="svg-max"  preserveAspectRatio="none" viewBox="0 0 60 64.299">
            <path d="M33.918,64.3C23.692,64.3,13.6,58.617,6.764,48.552-.389,37.908-3.481,21.947,5.856,10.026,13.108.808,23.413-2.135,35.643,1.546a54.138,54.138,0,0,1,16.121,8.23L49.879,12.13A51.764,51.764,0,0,0,34.706,4.389c-11.073-3.3-19.951-.788-26.5,7.492-8.4,10.684-5.517,25.268,1.077,35C17.1,58.4,29.508,63.665,40.9,60.3c9.118-2.693,14.445-8.1,15.822-16.081,1.766-10.3-3.851-21.857-8.5-25.268l1.766-2.414c5.387,3.91,11.672,16.51,9.666,28.151C58.568,51.016,54.577,59.346,41.7,63.136A27.443,27.443,0,0,1,33.918,64.3Z" transform="translate(-0.037 -0.004)"/>
        </svg>
    )
}


export { SocIconStroke1, SocIconStroke2, SocIconStroke3, SocIconStroke4 };
