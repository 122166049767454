import React, { memo as Memo, Fragment, useMemo } from 'react'
import NextImage from 'next/image'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Components
import CustomLink from '../CustomLink'

const Logo = Memo(({ lightHeader, darkMode, opened, isMobileView, onClick }) => {

    const isLightLogo = useMemo(() => ((lightHeader === 'reversed' && !darkMode) || (lightHeader && lightHeader !== 'reversed') || (darkMode && !lightHeader) || opened), [lightHeader, darkMode, opened])

    const url = useMemo(() => `/images/headerLogoMenu/Logo${isMobileView ? 'Mobile' : ''}${isLightLogo ? '' : 'Black'}.svg`, [isLightLogo, isMobileView])

    return (
        <CustomLink url="/" className="Logo" content={
            <Fragment>
                {
                    <NextImage
                        src={url}
                        width={200}
                        height={50}
                        style={{ height: '100% !important', width: '200px' }}
                        alt="logo"
                        onClick={onClick}
                        priority
                    />
                }
            </Fragment>
        } />
    )
})


export default withUIContext(Logo, ['toggleDarkMode', 'darkMode', 'screenSizes', 'winWidth'])