import React, { memo as Memo, useRef, useCallback, useEffect, useMemo } from 'react'
import { withRouter } from 'next/router'
import { TimelineLite, TweenMax } from 'gsap'

//* Components
import { CopyToClipboard } from 'react-copy-to-clipboard'

//* HOCs
import withDataContext from '@context/consumerHOC/DataConsumer'

//* Images Svg
import { SocIconStroke1, SocIconStroke2, SocIconStroke3, SocIconStroke4 } from '../SvgComponents/SocIconStroke'

//* Style 
import style from './style.module.scss'

const SocIcon = Memo(({ global, type, router, menuIcon, whiteIcon, isSocial, isFooter, className }) => {

    const socialObj = useMemo(() => global[router.locale].socials.reduce((o, i) => ({ ...o, [i.name]: i }), {}), [])

    //! States
    const cardRef = useRef();

    const url = useMemo(() => process.env.host + router.asPath, [router]);
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

    //! Animation
    const tl = useMemo(() => new TimelineLite({ paused: true }), [])

    //! Mouse Over
    const mouseOver = useCallback((e) => {
        if (cardRef.current) {
            tl.to(cardRef.current.childNodes[e], 0.2, { rotation: (e % 2 == 0 ? 15 : -15) }).play()
        }
    }, [cardRef])

    //! Mouse Over
    const mouseleave = useCallback((e) => {
        tl.to(cardRef.current.childNodes[e], 0.2, { rotation: 0 }).play()
    }, [cardRef])

    const Animation = useCallback(() => {

        const el = Math.floor(Math.random() * Math.floor(isFooter ? 7 : 3))

        if (cardRef.current && type !== 1 && menuIcon === true) {

            TweenMax.set(cardRef.current.childNodes[el].childNodes[0], { height: 0, duration: 0.6, delay: 7 })
            TweenMax.to(cardRef.current.childNodes[el].childNodes[0], 0.7,
                {
                    height: '100%',
                    delay: 7.1,
                    onComplete: () => {
                        Animation();
                    }
                })
        }
    }, [type, menuIcon, cardRef, isFooter])

    useEffect(() => Animation(), [])

    return (
        <div className={`${className || ''} ${whiteIcon ? "whiteIcon" : null} ${type === 1 ? style.soc_icon_wrap1 : style.soc_icon_wrap2}`} ref={cardRef}>
            <a href={isSocial ? socialObj.facebook.url : facebookUrl} aria-label="Concept Studio Facebook" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(0)} onMouseLeave={() => mouseleave(0)} >
                <div className={`social-icon-border ${style.soc_icon_border}`}>
                    <span className={`${style.soc_icon} ${type === 1 ? "cs-facebook" : " cs-facebook-circle"}`} />
                </div>
                {
                    type === 1 ? <SocIconStroke1 /> : ""
                }
            </a>
            <a href={isSocial ? socialObj.twitter.url : twitterUrl} aria-label="Concept Studio Twitter" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(1)} onMouseLeave={() => mouseleave(1)}>
                <div className={`social-icon-border ${style.soc_icon_border}`}>
                    <span className={`${style.soc_icon} cs-twitter`} />
                </div>
                {
                    type === 1 ? <SocIconStroke2 /> : ""
                }
            </a>
            <a href={isSocial ? socialObj.linkedin.url : linkedinUrl} aria-label="Concept Studio Linkedin" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(2)} onMouseLeave={() => mouseleave(2)}>
                <div className={`social-icon-border ${style.soc_icon_border}`}>
                    <span className={`${style.soc_icon} cs-linkedin`} />
                </div>
                {
                    type === 1 ? <SocIconStroke3 /> : ""
                }
            </a>
            {isFooter && <>
                {socialObj?.instagram?.url ? <a href={socialObj.instagram.url} aria-label="Concept Studio instagram" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(3)} onMouseLeave={() => mouseleave(3)}>
                    <div className={`social-icon-border ${style.soc_icon_border}`}>
                        <span className={`${style.soc_icon} cs-instagram`} />
                    </div>
                </a> : ''}

                {socialObj?.behance?.url ? <a href={socialObj.behance.url} aria-label="Concept Studio behance" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(4)} onMouseLeave={() => mouseleave(4)}>
                    <div className={`social-icon-border ${style.soc_icon_border}`}>
                        <span className={`${style.soc_icon} cs-behance`} />
                    </div>
                </a> : ''}

                {socialObj?.clutch?.url ? <a href={socialObj.clutch.url} aria-label="Concept Studio clutch" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(5)} onMouseLeave={() => mouseleave(5)}>
                    <div className={`social-icon-border ${style.soc_icon_border}`}>
                        <span className={`${style.soc_icon} cs-clutch`} />
                    </div>
                </a> : ''}

                {socialObj?.vimeo?.url ? <a href={socialObj.vimeo.url} aria-label="Concept Studio vimeo" target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(6)} onMouseLeave={() => mouseleave(6)}>
                    <div className={`social-icon-border ${style.soc_icon_border}`}>
                        <span className={`${style.soc_icon} cs-vimeo`} />
                    </div>
                </a> : ''}
            </>}
            {type === 1 ?
                <CopyToClipboard
                    text={url}>
                    <div target="_blank" rel="noopener noreferrer" className={`soc-icon-item crCircle ${style.soc_icon_item}`} onMouseEnter={() => mouseOver(3)} onMouseLeave={() => mouseleave(3)}>
                        <div className={`social-icon-border ${style.soc_icon_border}`}>
                            <span className={`${style.soc_icon}  cs-copy-link`} />
                        </div>
                        <SocIconStroke4 />
                    </div>
                </CopyToClipboard>
                :
                ''
            }
        </div>
    )
})
export default withRouter(withDataContext(SocIcon, ['fullUrl', 'global']));