import Request from './__request'
import { createClient } from 'contentful'

class CfApi extends Request {

    async getContentTypePosts(content_type, params = {}, selectedLang) {

        return await this.#getEntries({
            "content_type": content_type,
            "locale": this.languages[selectedLang || this.defaultLanguage || 'en'].cfCode,
            "order": "-fields.createdAt",
            ...params
        })
            .then(res => ({
                ...res,
                items: res.items.reduce((o, i) => ({ ...o, [i.fields.slug]: i }), {})
            }))
    }

    async getSinglePost(content_type, slug, selectedLang) {
        return await this.#getEntries({
            "content_type": content_type,
            "locale": this.languages[selectedLang || this.defaultLanguage].cfCode,
            "fields.slug": slug
        })
    }

    #getEntries = (params) => {
        return this.#contentful.getEntries(params)
            .then(res => res)
            .catch(err => Promise.reject(err))
    }

    #contentful = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE,
        accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN
    })
}

export default new CfApi()