import React, { memo as Memo, useMemo } from 'react'
import Lottie from 'react-lottie'
import motionJSON from './motionJSON.json'
import rotationJSON from './rotationJSON.json'

const RotationAnimation = Memo(({ motion }) => {
    const defaultOptions = useMemo(() => ({
        loop: true,
        autoplay: true,
        animationData: motion ? motionJSON : rotationJSON,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }), [])

    return (
        <Lottie
            options={defaultOptions}
            height={'auto'}
            width={'100%'}
            isStopped={false}
            isPaused={false}
        />
    )
})

export default RotationAnimation