import React, { memo as Memo, useMemo } from 'react'
import Link from "next/link"

//* HOCs
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'
import { withRouter } from 'next/router'

const LanguageSwitcher = Memo(({ selectedLang, languages, ...props }) => {

    const langs = useMemo(() => {
        return Object.keys(languages).map(lang => {

            return (
                <li key={lang} className="lengItem p-l font-semi-bold font-poppins">
                    {selectedLang !== lang ?
                        <>
                            <span className='hideText'>{languages[lang].params.name}</span>
                            <span className='showText'>
                                <Link
                                    className="crCircle"
                                    href={props.router.asPath}
                                    locale={lang}
                                >
                                    {languages[lang].params.name}
                                </Link>
                            </span>
                        </>
                        :
                        <>
                            <span className='hideText'>{languages[lang].params.name}</span>
                            <span className='showText font-italic'>
                                {languages[lang].params.name}
                            </span>
                        </>
                    }
                </li>
            )
        })
    }, [selectedLang, languages, props.router.asPath])

    return (
        <ul className="lengWrap">
            {langs}
        </ul>
    )
})

export default withRouter(withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']))