import React, { memo as Memo, useMemo, useEffect, useCallback, useRef, useState } from 'react'
import { TimelineLite } from 'gsap'

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Config
import config from 'config.json'

//!component
import CustomLink from '@components/global/CustomLink'
import Translate from '@components/global/Translate';

//*style 
import style from './style.module.scss'

const ContactTickerElement = Memo(props => {

    //! Ref's
    const textRef = useRef()
    const ref = useRef()

    //! States
    const [play, setPlay] = useState(true)
    const [height, setHeight] = useState(0)

    //! Global Varibales


    const tl = useMemo(() => new TimelineLite({ repeat: -1 }), []);

    //! Animation Start

    const tl1 = useMemo(() => new TimelineLite({ paused: true }), [])

    useEffect(() => {
        if (props.active) {
            tl1.to(ref.current, 0.7, { x: "0%" })
                .to(ref.current, 0, { css: { zIndex: 1 } }).play()
        }
        else {
            tl1.to(ref.current, 0, { x: "100%" })
                .to(ref.current, 0, { css: { zIndex: 3 } }).play()
        }
    }, [props.active])

    useEffect(() => {
        tl
            .set(textRef.current, {
                x: "-50%"
            })
            .to(textRef.current, 30, {
                x: "0%",
                ease: "none"
            })
        let i = 0
        const int = setInterval(() => {
            if (document.body.clientHeight > 0) {
                setHeight(document.body.clientHeight)
                clearInterval(int)
            }

        }, 15)
        window.addEventListener('scroll', onScroll)

        return () => {
            tl.kill()
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    const onScroll = useCallback(() => {
        const el = document.querySelector('.shapes')
        if (el) {
            el.style.right = `${window.scrollY}px`
        }
    }, [])

    useEffect(() => {
        play && !props.active ? tl.play() : tl.pause()
    }, [play, props.active])

    const ziggy = useMemo(() => (
        <svg viewBox="0 0 265.16 179.49">
            <path d="M156.79,43.19,128.53,0,109.88,50,68.58,19.69l-4.4,42L16.83,49.42,32,89.23,0,106.67l36.35,15.51-2.17,38.34,42.37-22,9.14,40.9,33.92-31.75,29.57,31.82,22.61-39.88,47.45,20.88L215,114.74l50.21-7.44L220.65,84.16l24.23-47.29L200.2,55.64l-4.1-43.8Z" />
        </svg>
    ), [])

    const rolly = useMemo(() => (
        <svg viewBox="0 0 296.126 296.126">
            <ellipse cx="148.063" cy="148.063" rx="148.063" ry="148.063" />
        </svg>
    ), [])

    const peezzy = useMemo(() => (
        <svg viewBox="0 0 385.753 318.305">
            <path d="M338.9,183.827,261.88,1.582A2.659,2.659,0,0,0,259.416,0H79.484A2.659,2.659,0,0,0,77.02,1.583L0,183.827S339.6,185.485,338.9,183.827Z" transform="translate(385.753 164.448) rotate(153)" />
        </svg>
    ), [])

    const sharpy = useMemo(() => (
        <svg viewBox="0 0 149.76 178.66">
            <path d="M141.1,178.66,0,171.82C4.78,73.22,71.83-3.65,149.76.13h0Z" />
        </svg>
    ), [])

    const shapes = useMemo(() => {
        return height ? (
            <div className={`shapes ${style.shapes}`} style={{ width: height }}>
                <div className={`${style.shape}`}>{peezzy}</div>
                <div className={`${style.shape}`}>{rolly}</div>
                <div className={`${style.shape}`}>{sharpy}</div>
                <div className={`${style.shape}`}>{ziggy}</div>
            </div>
        ) : ''

    }, [height])

    return (
        <section className={style.ContactTeamPopWrap} ref={ref}>
            <div className={style.ContactTeamTicker} >
                <CustomLink url={config.routes.default['team'].path}>
                    <div className={style.ContactTickerText} ref={textRef}>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                        <span className='h3 font-poppins font-regular contactTop'>
                            <Translate val={"joinTeam"} />
                        </span>
                    </div>

                    {shapes}
                </CustomLink>
            </div>
        </section>
    )
})

export default withUIContext(ContactTickerElement, ['lightHeader'])