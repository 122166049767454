import React, { Component } from 'react';
import { withRouter } from 'next/router';

//* Components
import _ from 'lodash'

//* Helpers
import { objectToQueryString } from '@helpers/queryToObject';

//* Context
const UIContext = React.createContext();
export const UIConsumer = UIContext.Consumer;

//* Global Variables
const errorsInitialState = {
    formError: {
        error: false,
        msg: ''
    },
    systemError: {
        error: false,
        msg: ''
    }
}

class UIProvider extends Component {

    //! States
    state = {
        popupIsOpen: false,
        highComponent: null,
        popupComponent: null,
        loading: false,
        queryObj: '',
        preloader: true,
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            screen3XL: 2560,
            screen2XL: 1920,
            screenXL: 1440,
            screenL: 1280,
            screenM: 1024,
            screenS: 768,
            screenXS: 601,
        },
        colors: {},
        darkMode: false,
        lightHeader: false,
        ...errorsInitialState
    }


    //! Methods
    methods = {
        addHighComponent: this.addHighComponent.bind(this),
        removeHighComponent: this.removeHighComponent.bind(this),
        toggleLightHeader: this.toggleLightHeader.bind(this),
        removeQueryFromSearch: this.removeQueryFromSearch.bind(this),
        addErrorMsg: this.addErrorMsg.bind(this),
        removeErrorMsg: this.removeErrorMsg.bind(this),
        disableDomScroll: this.disableDomScroll.bind(this),
        enableDomScroll: this.enableDomScroll.bind(this),
        toggleDarkMode: this.toggleDarkMode.bind(this),
        scrollToTop: this.scrollToTop.bind(this)
    }

    //! Esc Keydown
    escKeydown = (event) => {
        if (event.keyCode === 27) { //esc
            (this.state.popupIsOpen && !this.state.preloader && !this.state.loading) && this.closePopup()
        }
    }

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight
        })
    }

    //! Gett Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement)

        const screenSizes = {
            screen3XL: parseInt(root.getPropertyValue('--screen3XL')),
            screen2XL: parseInt(root.getPropertyValue('--screen2XL')),
            screenXL: parseInt(root.getPropertyValue('--screenXL')),
            screenL: parseInt(root.getPropertyValue('--screenL')),
            screenM: parseInt(root.getPropertyValue('--screenM')),
            screenS: parseInt(root.getPropertyValue('--screenS')),
            screenXS: parseInt(root.getPropertyValue('--screenXS'))
        }

        const colors = {
            black: root.getPropertyValue('--black').trim(),
            white: root.getPropertyValue('--white').trim(),
            color1: root.getPropertyValue('--color1').trim(),
            color2: root.getPropertyValue('--color2').trim()
        }

        this.setState({ screenSizes, colors })
    }

    //! Component Did Mount
    componentDidMount() {
        this.checkNotFoundPage()

        window.addEventListener('resize', this.screenResize)
        document.addEventListener("keydown", this.escKeydown, false);
        this.getScreenSizes()
        this.screenResize()
    }

    //! Component Did Update
    componentDidUpdate(prevProps) {
        this.checkNotFoundPage()
        if (!this.props.router.route.includes('/portfolio/[slug]') && !this.props.router.route.includes('/team') && this.state.lightHeader) {
            this.toggleLightHeader()
        }
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize)
        document.removeEventListener("keydown", this.escKeydown, false);
    }

    //! Toggle Light/Dark Header
    toggleLightHeader(light = false) {
        this.setState({ lightHeader: light })
    }

    //! Check Not Found Page
    checkNotFoundPage() {
        if (this.props.router.asPath === '/404') {
            this.disablePreloader();
        }
    }

    //! Add Error Message
    addErrorMsg(type, msg) {
        type === 'form'
            ?
            this.setState({
                formError: {
                    error: true,
                    msg
                }
            })
            :
            this.setState({
                systemError: {
                    error: true,
                    msg
                }
            })
    }

    //! Remove Error Message
    removeErrorMsg(type) {
        type === 'form'
            ?
            this.setState({
                formError: {
                    error: false,
                    msg: ''
                }
            })
            :
            this.setState({
                systemError: {
                    error: false,
                    msg: ''
                }
            })
    }

    //! Add High Component
    addHighComponent(component = null) {
        this.setState({
            highComponent: component
        })
    }

    //! Remove High Component
    removeHighComponent() {
        this.setState({
            highComponent: null
        })
    }

    //! Remove Query String From Search
    removeQueryFromSearch(key) {
        let search = '';

        if (key && this.state.queryObj[key]) {
            search = objectToQueryString(_.omit(this.state.queryObj, key))
        }

        search && this.props.history.push({ search })
    }

    //! Scroll To Top
    scrollToTop(behavior = 'instant') {
        window.scrollTo({
            top: 0,
            behavior
        })
    }

    //! Disable DOM Scroll
    disableDomScroll() {
        // document.querySelector('html').classList.add('hide-scroll')

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        // if any scroll is attempted, set this to the previous value 
        window.onscroll = function (e) {
            e.preventDefault()
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    //! Enable DOM Scroll
    enableDomScroll() {
        // document.querySelector('html').classList.remove('hide-scroll');

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = function () { };
        window.scrollTo(scrollLeft, scrollTop);
    }

    toggleDarkMode() {
        let root = document.documentElement;

        const color1 = this.state.colors.color1 === this.state.colors.black ? this.state.colors.white : this.state.colors.black,
            color2 = this.state.colors.color2 === this.state.colors.black ? this.state.colors.white : this.state.colors.black

        root.style.setProperty('--color1', color1);
        root.style.setProperty('--color2', color2);

        this.setState({
            darkMode: !this.state.darkMode,
            colors: { ...this.state.colors, color1, color2 }
        })
        return true
    }

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </UIContext.Provider>
        )
    }
}

export default withRouter(UIProvider);
