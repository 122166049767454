import React, { memo as Memo, useState, useEffect, Fragment } from 'react'
import { withRouter } from 'next/router';

//* HOCs
import withUIContext from '@context/consumerHOC/UIConsumer'

//* Components
import Cursor from './global/Cursor'
import MotionPopup from './global/MotionPopup'
import OrientationPopup from './global/OrientationPopup'

const HighLevelComponent = Memo(props => {

    const [touchDevice, setTouchDevice] = useState(true)

    useEffect(() => {
        setTouchDevice('ontouchstart' in window || navigator.maxTouchPoints)
    }, [])

    return (
        <Fragment>
            {props.highComponent}

            {(!!props.isApple && !!touchDevice) && <MotionPopup />}

            {(!touchDevice && !props.isMobileView) && <Cursor />}

            {props.isMobileView && <OrientationPopup />}
        </Fragment>
    )
})

export default withRouter(
    withUIContext(
        HighLevelComponent,
        ['highComponent']
    )
)