import React, { Component } from 'react';
import { withRouter } from 'next/router';
import _ from 'lodash'

//* HOCs
import withUIContext from '../consumerHOC/UIConsumer'
import withLanguageContext from '../consumerHOC/LanguageConsumer'

import { getData } from '@helpers/getData'

export const DataContext = React.createContext();

class DataProvider extends Component {

    state = { ...this.props.newData }

    componentDidMount() {
        this.props.scrollToTop()
    }

    componentDidUpdate(prevProps) {
        const lang = this.props.router.locale

        if (prevProps.router.asPath !== this.props.router.asPath || prevProps.router.locale !== this.props.router.locale) {
            this.props.scrollToTop((this.props.router.query.slug && prevProps.router.query.slug) && 'smooth')

            getData({ lang, state: this.state, router: this.props.router }).then((res) => {
                this.setState(res)
            })

        }
    }

    render() {
        return (
            <DataContext.Provider value={{ ...this.state, ...this.methods }}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

export default withRouter(
    withLanguageContext(
        withUIContext(
            DataProvider,
            ['scrollToTop']
        ),
        ['selectedLang']
    )
)