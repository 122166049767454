import axios from 'axios';
import _ from 'lodash'
import languages from '../translations/'

export default class Request {

    apiUrl = process.env.LARAVEL_API_URL;
    languages = _.transform(languages, (o, v, k) => o[k] = v.params)
    defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

    __request({ url, method, data, lang }) {
        const headers = {
            "Content-Type": "application/json",
            "Content-Language": lang || this.defaultLanguage,
        };

        return axios({ method, url, headers, data })
            .then(res => res.data)
            .catch(err => Promise.reject(err))
    }
}