import Request from './__request'

class LaravelApi extends Request {

    url(lang) {
        return `${this.host}${lang && lang !== this.defaultLanguage ? `/${lang}` : ''}${this.api}`
    }

    getPage({ pageSlug, lang }) {
        return this.__request({ url: `${this.apiUrl}pages/${pageSlug}`, method: 'get', lang })
    }

    getGlobal({ lang }) {
        return this.__request({ url: `${this.apiUrl}global-data`, method: 'get', lang })
    }

    getSinglePost({ postSlug, lang, queryParams }) {
        return this.__request({ url: `${this.apiUrl}${postSlug}`, method: 'get', lang })
    }

    contactMessage(data) {
        return this.__request({ url: `${this.apiUrl}contact-us`, method: 'post', data })
    }

    subscribe(data) {
        return this.__request({ url: `${this.apiUrl}subscribe`, method: 'post', data })
    }

    teamForm(data) {
        return this.__request({ url: `${this.apiUrl}team-member-cv`, method: 'post', data })
    }
}

export default new LaravelApi();
