import React, { memo as Memo } from 'react'
import Link from 'next/link'

//* HOCs
import withLanguageContext from '@context/consumerHOC/LanguageConsumer'

const CustomLink = Memo(({ selectedLang, url, as, className, nav, disableCr, prefetch = false, scroll, ...props }) => {
    return (
        <Link
            target={props.target || '_self'}
            className={`${!disableCr && 'crCircle'} ${className || ''}`}
            aria-label={props.name || typeof url == 'object' ? url.pathname : `/${selectedLang}${as || url}`}
            href={typeof url === 'object' ? { ...url, pathname: `${url.pathname}` } : `${url}`}
            prefetch={prefetch || undefined}
            scroll={scroll}
        >
            {props.children || props.content}
        </Link>
    )
})

export default withLanguageContext(CustomLink, ['selectedLang'])