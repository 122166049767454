import _ from 'lodash';

export const queryStringToObject = (url) => {
    return _.chain(url)
        .replace('?', '')
        .split('&')
        .map(_.partial(_.split, _, '=', 2))
        .fromPairs()
        .value()
}

export const objectToQueryString = obj => {
    if (obj) {
        const results = [];
        _.forOwn(obj, (value, key) => {
            if (Array.isArray(value)) {
                _.forOwn(value, value => {
                    results.push(`${key}=${value}`);
                });
            } else {
                results.push(`${key}=${value}`);
            }
        });
        return results.join('&');
    }
    return ''
};
