import App from 'next/app'
import React from 'react';
import Router from 'next/router';
import { Anonymous_Pro, Poppins } from '@next/font/google'
import NProgress from 'nprogress'; //nprogress module

//* Loading fonts
const fontAnonymousPro = Anonymous_Pro({
    weight: ['400', '700'],
    display: 'swap',
    subsets: ['latin']
})

const fontPoppins = Poppins({
    weight: ['300', '400', '500', '600', '700'],
    display: 'swap',
    subsets: ['latin']
})

//* Binding events
NProgress.configure({ minimum: 0.2 });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

//* Providers
import UIProvider from "@context/providers/UIProvider";
import DataProvider from "@context/providers/DataProvider";
import LanguageProvider from "@context/providers/LanguageProvider";

//* Components
import Header from '@components/global/Header'
import ContactTickerElement from '@components/pages/ContactTeam/ContactTickerElement'
import HighLevelComponent from '@components/HighLevelComponent'
// import ErrorMsg from '@components/global/error/ErrorMsg'

//* Helpers
import { getData, getPageParams } from '@helpers/getData'

//* Config
import config from '../src/config.json'

//* Global Style
import '@assets/styles/style.scss';


class MyApp extends App {

    static async getInitialProps({ Component, ctx, router, ...props }) {

        const isMobileView = (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)

        const isApple = (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent).match(/iPhone|iPad|Mac/i)

        if (!process.browser) {
            const newState = {
                header: {},
                footer: {},
                global: {},
                pages: {},
                cf: {},
                singleItem: {}
            }

            const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true })

            return {
                pageProps: {
                    ...data.newState,
                    pageParams: data.pageParams,
                    browser: false,
                    isMobileView
                },
                isApple,
                error: data.newState.error
            }
        }
        else {
            const pageParams = getPageParams({ lang: ctx.locale, router: ctx })

            return {
                pageProps: {
                    pageParams,
                    browser: true,
                    isMobileView,
                    error: !pageParams
                },
                isApple
            }
        }
    }

    render() {
        const { Component, pageProps, router, isApple } = this.props
        const route = router.route.split('/')

        return (
            <>
                <style jsx global>{`
                    :root {
                        --fontFamily1: ${fontAnonymousPro.style.fontFamily};
                        --fontFamily2: ${fontPoppins.style.fontFamily}
                    }
                `}</style>

                <LanguageProvider selectedLang={router.locale}>
                    <UIProvider>
                        <DataProvider newData={pageProps}>
                            <Header {...pageProps} selectedLang={router.locale} />

                            {pageProps.error ? <Error statusCode={404} /> : <Component {...pageProps} config={config.routes.default} selectedLang={router.locale} />}

                            <HighLevelComponent isApple={isApple} isMobileView={pageProps.isMobileView} />

                            {/* <ErrorMsg /> */}

                            {
                                ((route[route.length - 1] === 'team')) &&
                                <ContactTickerElement active={route[route.length - 1] === 'team'} />
                            }

                        </DataProvider>
                    </UIProvider>
                </LanguageProvider>
            </>
        );
    }
}

export default MyApp
